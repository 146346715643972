div[data-type='row']:hover {
  background-color: #f9f9f9;
}

div[role='button']:not(.MuiSelect-select):not([aria-disabled='true'])::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  content: ' ';
}

div[role='button']:not(.MuiSelect-select):not(
    [aria-disabled='true']
  ):hover::before {
  background-color: currentColor;
  opacity: 0.1;
}

a:hover {
  text-decoration: underline;
}

@keyframes animateX {
  0% {
    transform: translate3D(0, 0, 0);
  }
  100% {
    transform: translate3D(calc(-100vw), 0, 0);
  }
}

@keyframes opacity {
  1% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

.emoji {
  opacity: 0;
  position: absolute;
  right: 0px;
  width: 20px;
  height: 24px;
  animation:
    animateX 4s 1,
    opacity 4s 1;
}

.rbc-calendar {
  font-family: 'Nunito', sans-serif !important;
}

.rbc-event {
  background-color: #009e9a !important;
}

.rbc-show-more {
  color: #009e9a !important;
}
